<template>
    <page-content>
        <a-card class="data-card" title="数据概览">

            <div class="data-content">

                <div class="item clickable" @click="$router.push('/user/list')">
                    <div class="title">用户数</div>
                    <div class="value">{{overview.userCount}}</div>
                </div>

                <div class="item clickable" @click="$router.push('/machine/brand')">
                    <div class="title">品牌数</div>
                    <div class="value">{{overview.brandCount}}</div>
                </div>

                <div class="item clickable" @click="$router.push('/machine/classify')">
                    <div class="title">分类数</div>
                    <div class="value">{{overview.classifyCount}}</div>
                </div>

                <div class="item clickable" @click="$router.push('/machine/product')">
                    <div class="title">产品数</div>
                    <div class="value">{{overview.productCount}}</div>
                </div>

            </div>

        </a-card>


    </page-content>
</template>
<script>
    export default {
        data(){
            return {
                overview:{}
            }
        },
        created() {
            this.$get("admin/home/wechat/overview").then(suc=>{
                this.overview=suc.data
            })
        },
        methods:{
            toOrder(status){
                this.$router.push("/mall/order?status="+status)
            }
        }
    }
</script>
<style lang="less" scoped>
    .data-card {
        margin-bottom: 20px;

        .data-content {
            display: flex;

            .item{
                text-align: center;
                width: 160px;
                margin: 0 10px;
                padding: 15px 0;
                border-radius: 5px;
            }

            .title{
                color: #666666;
                font-size: 16px;
            }

            .value{
                color: #333333;
                font-weight: 600;
                font-size: 24px;
                margin-top: 10px;
            }
        }

        .clickable{
            cursor: pointer;
        }

        .clickable:hover{
            background: #F8F8F8;
        }
    }

</style>
