import axios from 'axios'
import { message, Modal, notification } from 'ant-design-vue'
import moment from 'moment'
import store from '../store'
import db from '@/utils/localstorage'
import config from '../config/config'
moment.locale('zh-cn')

import Vue from "vue"

// 统一配置
let FEBS_REQUEST = axios.create({
  baseURL: config.apiServer,
  responseType: 'json',
  validateStatus (status) {
    // 200 外的状态码都认定为失败
    return status === 200
  }
})

// 拦截请求
FEBS_REQUEST.interceptors.request.use((config) => {
  // console.log(config)
  let expireTime = store.state.account.expireTime
  let now = moment().format('YYYYMMDDHHmmss')
  // 让token早10秒种过期，提升“请重新登录”弹窗体验
  // if (now - expireTime >= -10) {
  //   Modal.error({
  //     title: '登录已过期',
  //     content: '很抱歉，登录已过期，请重新登录',
  //     okText: '重新登录',
  //     mask: false,
  //     onOk: () => {
  //       return new Promise((resolve, reject) => {
  //         db.clear()
  //         location.reload()
  //       })
  //     }
  //   })
  // }
  // 有 token就带上
  if (store.state.account.token) {
    config.headers.Authentication = store.state.account.token
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// 拦截响应
FEBS_REQUEST.interceptors.response.use((config) => {
  console.log(config)
  if(config.data.code && config.data.code>0){
    notification.error({
      message: '系统提示',
      description: config.data.message,
      duration: 4
    })
    return Promise.reject(config.data)
  }
  if(config.data.data){
    config.data = config.data.data
  }
  return config
}, (error) => {
  if (error.response) {
    console.log(error.response)
    let errorMessage = error.response.data === null ? '系统内部异常，请联系网站管理员' : error.response.data.message
    switch (error.response.status) {
      case 404:
        notification.error({
          message: '系统提示',
          description: '很抱歉，资源未找到',
          duration: 4
        })
        break
      case 403:
        notification.warn({
          message: '系统提示',
          description: '很抱歉，您没有权限访问该资源',
          duration: 4
        })
        break;
      case 401:
        notification.warn({
          message: '系统提示',
          description: '请重新登录',
          duration: 3
        })

        // db.clear()
        // location.reload()

        break
      default:
        notification.error({
          message: '系统提示',
          description: errorMessage,
          duration: 4
        })
        break
    }
  }
  return Promise.reject(error)
})

const requestConfig = {
  transformRequest: [(params) => {
    let result = ''
    Object.keys(params).forEach((key) => {
      if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
        result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
      }
    })
    return result
  }],
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}
function buildUrlAndParams (url, params) {
  let _params
  if (Object.is(params, undefined)) {
    _params = ''
  } else {
    _params = '?'
    for (let key in params) {
      if (params.hasOwnProperty(key) && params[key] !== null) {
        _params += `${key}=${params[key]}&`
      }
    }
  }
  return `${url}${_params}`
}

const request = {
  postJson (url, params) {
    return FEBS_REQUEST.post(url, params)
  },
  post (url, params) {
    return FEBS_REQUEST.post(url, params, requestConfig)
  },
  put (url, params) {
    return FEBS_REQUEST.put(url, params, requestConfig)
  },
  get (url, params) {
    return FEBS_REQUEST.get(buildUrlAndParams(url,params))
  },
  delete (url, params) {
    return FEBS_REQUEST.delete(buildUrlAndParams(url,params))
  },
  export (url, params = {},fileName='导出结果') {
    message.loading('导出数据中')
    return FEBS_REQUEST.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      responseType: 'blob'
    }).then((r) => {
      const content = r.data
      const blob = new Blob([content])
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = `${fileName}_${new Date().getTime()}.xlsx`
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        navigator.msSaveBlob(blob, fileName)
      }
    }).catch((r) => {
      console.error(r)
      message.error('导出失败')
    })
  },
  download (url, params, filename) {
    message.loading('文件传输中')
    return FEBS_REQUEST.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      responseType: 'blob'
    }).then((r) => {
      const content = r.data
      const blob = new Blob([content])
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = filename
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        navigator.msSaveBlob(blob, filename)
      }
    }).catch((r) => {
      console.error(r)
      message.error('下载失败')
    })
  },
  upload (url, params) {
    return FEBS_REQUEST.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default request
