<!--递归，无限层级导航栏-->
<template>
  <a-menu :mode="mode" :theme="theme" :openKeys="openKeys" :selectedKeys="selectedKeys"
          @openChange="onOpenChange" style="background: transparent;border-right: none">
    <template v-for="menu in menuData">
      <recursion-sub-menu  :menu="menu"/>
    </template>
  </a-menu>
</template>


<script>
  import RecursionSubMenu from './RecursionSubMenu.vue'

  export default {
    components: {RecursionSubMenu},
    name: 'IMenu',
    props: {
      menuData: {
        type: Array,
        required: true
      },
      theme: {
        type: String,
        required: false,
        default: 'dark'
      },
      mode: {
        type: String,
        required: false,
        default: 'inline'
      },
      collapsed: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data () {
      return {
        openKeys: [],
//        cachedOpenKeys:[],
        selectedKeys:[],
      }
    },
    created () {
      this.updateMenu()
    },
    watch: {
      collapsed (val) {
        if (val) {
          this.cachedOpenKeys = this.openKeys
          this.openKeys = []
        } else {
          this.openKeys = this.cachedOpenKeys
        }
      },
      '$route': function () {
        this.updateMenu()
      }
    },
    methods: {
      onOpenChange (openKeys) {
        //自动收缩其他项
        if(openKeys.length>=2){
          this.openKeys = this.getSubPath(openKeys[openKeys.length-1])
        }else{
          this.openKeys = openKeys
        }
      },
      getSubPath (path) {
        var subPathArr = []
        subPathArr.push(path)
        while (path.lastIndexOf("/")>0){
          path = path.substr(0,path.lastIndexOf("/"))
          subPathArr.push(path)
        }
        return subPathArr
      },
      updateMenu () {
        var path = this.$route.path
        this.openKeys = this.getSubPath(path)
        this.selectedKeys = this.getSubPath(path)
      },
    },
  }
</script>

<style>
/*@import "../../../static/less/Zwasu.less";*/
.ant-menu-submenu > .ant-menu {
  background: transparent !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background: rgba(255,255,255,0.7) !important;
}
</style>
