import Vue from 'vue'
import Router from 'vue-router'
import GlobalLayout from "../views/common/GlobalLayout";
import EmptyPageView from '@/views/common/EmptyPageView'
import db from '@/utils/localstorage'
import request from '@/utils/request'
import Auth from '../views/oauth/authorize'

import Config from '../config/config'


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(Router)

let constRouter = [
  {
    path: '/login',
    name: '登录页',
    component: Config.loginPage
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/oauth',
    name: 'Oauth授权',
    component: Auth
  },
  {
    path: '/',
    component: GlobalLayout,
    children:[
      {
        path: '/home',
        name: '首页',
        component: Config.homePage
      },
    ]
  },
]

let router = new Router({
  routes: constRouter
})

const whiteList = ['/login','/oauth']

let asyncRouter

console.log('beforeEach')
// 导航守卫，渲染动态路由
router.beforeEach((to, from, next) => {
  console.log('router.beforeEach:'+to.path)
  if (whiteList.indexOf(to.path) !== -1) {
    next()
    return
  }
  let token = db.get('USER_TOKEN')
  let user = db.get('USER')
  let userRouter  = get('USER_ROUTER')

  console.log(token)
  console.log(user)
  if (!token.length || !user || !user.userId) {
    console.log("gotoLogin")
    next('/login')
    return
  }

  if(asyncRouter){
    next()
    return
  }

  // console.log(userRouter)
  // if(userRouter){
  //   asyncRouter = userRouter
  //   next()
  // }

  request.get("menu/router").then((res) => {
    asyncRouter = res.data
    save('USER_ROUTER', asyncRouter)

    expandRoutes(asyncRouter)

    routeList.forEach(item => {
      item.children = []

      constRouter[3].children.push(item)
    })

    router.addRoutes(constRouter)
    next({
      ...to,
      replace: true
    })

  })

})

function expandRoutes(asyncRouter){
  asyncRouter.forEach((route) => {

    if (route.component) {
      route.component = view(route.component )
      routeList.push(route)
    }

    if (route.children && route.children.length) {
      route.children = expandRoutes(route.children)
    }

  })
}

var routeList = []

function go (to, next) {
  routeList = []
  asyncRouter = filterAsyncRouter(asyncRouter)
  routeList.splice(0, 1)
  asyncRouter[0].children = routeList

  routeList.forEach(item => {
    item.children = []

    constRouter[3].children.push(item)
  })

  router.addRoutes(constRouter)
  next({
    ...to,
    replace: true
  })
}

function save (name, data) {
  localStorage.setItem(name, JSON.stringify(data))
}

function get (name) {
  return JSON.parse(localStorage.getItem(name))
}

function filterAsyncRouter (routes) {

  return routes.filter((route) => {
    routeList.push(route)
    let component = route.component
    if (component) {
      switch (route.component) {
        case 'MenuView':
          route.component = MenuView
          break
        case 'PageView':
        // route.component = PageView
        // break
        case 'EmptyPageView':
        case '':
          route.component = EmptyPageView
          break
        case 'HomePageView':
          route.component = HomePageView
          break
        default:
          route.component = view(component)
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children)
      }
      return true
    }
  })
}

function view (path) {
  return function (resolve) {
    import(`@/views/${path}.vue`).then(mod => {
      resolve(mod)
    })
  }
}

export default router
