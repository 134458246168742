import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import request from '@/utils/request'
import db from '@/utils/localstorage'


import "./_dependency/ant-design"
import "./_dependency/vue-video-player"
import "./_dependency/amap"
import "./_dependency/bmap"
import "./_dependency/apex-charts"
import "./_dependency/vue-quill-editor"
import "./_dependency/my-components"
import "./_dependency/my-icon"
import "./_dependency/vue-color"


// import '../static/css/base.css'
import '@/utils/install'


Vue.use(db)

Vue.use({
  install (Vue) {
    Vue.prototype.$db = db
  }
})

Vue.prototype.$post = request.post
Vue.prototype.$postJson = request.postJson
Vue.prototype.$get = request.get
Vue.prototype.$put = request.put
Vue.prototype.$delete = request.delete
Vue.prototype.$export = request.export
Vue.prototype.$download = request.download
Vue.prototype.$upload = request.upload


/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


