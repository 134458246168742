var self = {
  pagination () {
    return {
      pageSizeOptions: ['10', '20', '50', '100'],
      defaultCurrent: 1,
      current: 1,
      total: 0,
      pageSize: 10,
      defaultPageSize: 10,
      showQuickJumper: false,
      showSizeChanger: true,
      showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
    }
  }
}
export default self
